<template>
  <el-dialog
    v-model="dialogVisible"
    :title="dialogVisibletitle"
    :close-on-click-modal="false"
    width="900px"
  > 
    <template v-if="dialogVisibletitle==='新增岗位'||dialogVisibletitle==='修改岗位'">
      <div class="dp-f">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          status-icon
          label-width="150px"
          class="demo-ruleForm w100"
        >
          <el-form-item label="岗位名称" prop='postName'>
            <el-input v-model="ruleForm.postName" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入岗位名称"/>
          </el-form-item>
          <el-form-item label="岗位标识" prop='postCode'>
            <el-input v-model="ruleForm.postCode" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入岗位标识"/>
          </el-form-item>
          <el-form-item label="岗位描述" prop='postDesc'>
            <el-input v-model="ruleForm.postDesc" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入岗位描述"/>
          </el-form-item>
          <el-form-item label="排序" prop='sort'>
            <el-input-number
              v-model="ruleForm.sort"
              :min="1"
              :max="999"
              controls-position="right"
              :step="1"
            />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='180' height='40' CSStype=3 title="关闭" @buttonclick="dialogVisible = false"></oabutton>
        <oabutton class="searcML" width='180' height='40' :title="dialogVisibletitle==='新增岗位'?'添加':'修改'" CSStype=2 @buttonclick="submitForm()" v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
  import { reactive,ref,unref,nextTick,defineEmits } from 'vue'
  import {objdata_ret,objdata_set} from "@/utils/server/format";
  import {httpToken} from "@/utils/request";
  import qs from "qs";
  const dialogloading = ref(false);// 弹框按钮loading 显示
  const dialogVisible = ref(false)//弹框显示
  const dialogVisibletitle = ref(false)//弹框标题
  const emit = defineEmits(['Emit'])
  const id = ref(0);//操作的id
  const state = reactive({
  })
  const ruleFormRef = ref(null);//表单ref
  const ruleForm = reactive({//from 表单数据
    postName:'',//岗位名称
    postCode:'',//岗位标识
    postDesc:'',//岗位描述
    sort:1,//排序
  })
  const rules = reactive({//from 表单校验
    postName:[
      { required: true, message: '请输入岗位名称', trigger: 'blur' },
    ],
    postCode:[
      { required: true, message: '请输入岗位标识', trigger: 'blur' },
    ],
    postDesc:[
      { required: true, message: '请输入岗位描述', trigger: 'blur' },
    ],
    sort:[
      { required: true, message: '请选择排序', trigger: 'blur' },
    ],
  })
  // 表单重置
  let fromDataReset=(()=>{
    objdata_ret(ruleForm)
    ruleForm.sort=1
  })
  let add=(()=>{
    fromDataReset()
    dialogloading.value=false
    dialogVisibletitle.value='新增岗位'
    dialogVisible.value=true
    nextTick(()=>{
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
  let editclick=((row)=>{
    fromDataReset()
    objdata_set(ruleForm,row)
    id.value=row.postId
    dialogloading.value=false
    dialogVisibletitle.value='修改岗位'
    dialogVisible.value=true
    nextTick(()=>{
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
  // 表单提交
  const submitForm = async () => {
    dialogloading.value=true
    const form = unref(ruleFormRef)
    if (!form) {
      dialogloading.value=false
      return
    }
    await form.validate((valid) => {
      if (valid) {
        if(dialogVisibletitle.value==='新增岗位'){
          dataGeneration(ruleForm)
        }else if(dialogVisibletitle.value==='修改岗位'){
          let ruleFormjson={
            postId:id.value,
            ...ruleForm
          }
          dataGeneration(ruleFormjson,'/admin/sys-post/update')
        }
      }else{
        dialogloading.value=false
      }
    })
  }
  // 数据生成
  let dataGeneration=((json,url='/admin/sys-post/add')=>{
    httpToken({
      method: "post",
      url: url,
      data: json,
    }).then((res) => {
      dialogloading.value=false
      dialogVisible.value=false
      emit('Emit')
    }).catch(()=>{
      dialogloading.value=false
    })
  })
  defineExpose({
    add,
    editclick,
  });
</script>